import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-grid-system"
import { useBooks } from "../hooks/useBooks"
import BookThumb from "../components/bookThumb"
import { Display, Lead } from "../components/typography"
import SubjectList from "../components/subjectList"
import PageTitle from "../components/pageTitle"
import InnerSearchForm from "../components/innerSearchForm"
import { ButtonGroup, ExternalLinkButtonSecondary } from "../components/button"
import { Box } from "theme-ui"

const CataloguePage = ({ data: { catalogue } }) => {
  function isSameCatalogue(book) {
    const sameCatalogue = book => book.originalId === catalogue.originalId
    return book.catalogue.some(sameCatalogue)
  }
  const books = useBooks().filter(book => isSameCatalogue(book))

  return (
    <Layout>
      <SEO title={catalogue.name} />
      <Box sx={{ mt: [3, 4, 4] }}>
        <InnerSearchForm />
      </Box>
      <Wrapper>
        <Container>
          <Row>
            <Col>
              <PageTitle>
                <Display>{catalogue.name}</Display>
                <ButtonGroup>
                  <ExternalLinkButtonSecondary
                    href={catalogue.link}
                    target="blank"
                    rel="noopener nofollow"
                  >
                    Sfoglia catalogo
                  </ExternalLinkButtonSecondary>
                  <ExternalLinkButtonSecondary
                    href={catalogue.download.url}
                    target="blank"
                    rel="noopener nofollow"
                  >
                    Scarica catalogo
                  </ExternalLinkButtonSecondary>
                </ButtonGroup>
                <Lead>Tutte le materie</Lead>
              </PageTitle>
            </Col>
          </Row>
          <CustomRow gutterWidth={64}>
            <Col md={3}>
              <SubjectList books={books} catalogue={catalogue} />
            </Col>
            <Col md={9}>
              <Row>
                {books.map(book => (
                  <Col xs={6} sm={6} lg={4} key={book.originalId}>
                    <BookThumb book={book} />
                  </Col>
                ))}
              </Row>
            </Col>
          </CustomRow>
        </Container>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  & > * {
    margin-bottom: 6rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const CustomRow = styled(Row)`
  @media screen and (max-width: 768px) {
    & > * {
      margin-bottom: 4rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

export default CataloguePage

export const query = graphql`
  query CatalogueQuery($originalId: String!) {
    catalogue: datoCmsCatalogue(originalId: { eq: $originalId }) {
      name
      slug
      originalId
      download {
        url
      }
      link
    }
  }
`
